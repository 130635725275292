import Vue from 'vue'
import VueRouter from 'vue-router'
import index from '@/views/index.vue'
import home from '@/views/home.vue'
import login from '@/views/login.vue'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/',
    redirect: '/home',
    component: index,
    children: [
      {
        path: '/home',
        name: 'home',
        component: home
      },
      {
        path: '/manager/node',
        name: 'node',
        component: () => import(/* webpackChunkName: "node" */ '@/views/system/node.vue')
      },
      {
        path: '/manager/nodes',
        name: 'nodes',
        component: () => import(/* webpackChunkName: "node" */ '@/views/system/nodes.vue')
      },
      {
        path: '/manager/menu',
        name: 'menu',
        component: () => import(/* webpackChunkName: "menu" */ '@/views/system/menu.vue')
      },
      {
        path: '/manager/admin',
        name: 'admin',
        component: () => import(/* webpackChunkName: "user" */ '@/views/system/admin.vue')
      },
      {
        path: '/manager/role',
        name: 'role',
        component: () => import(/* webpackChunkName: "role" */ '@/views/system/role.vue')
      },
      {
        path: '/manager/optlog',
        name: 'optlog',
        component: () => import(/* webpackChunkName: "optlog" */ '@/views/system/optlog.vue')
      },
      {
        path: '/depart/index',
        name: 'depart',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/depart/index.vue')
      },
      {
        path: '/manager/user',
        name: 'user',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/system/user.vue')
      },
      {
        path: '/manager/article',
        name: 'article',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/system/article.vue')
      },
      {
        path: '/grade/index',
        name: 'grade',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/grade/index.vue')
      },
      {
        path: '/projects/index',
        name: 'projects',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/projects/index.vue')
      },
      {
        path: '/order/index',
        name: 'order',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/order/index.vue')
      },
      {
        path: '/member/index',
        name: 'member',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/member/index.vue')
      },
      {
        path: '/apply/index',
        name: 'member',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/apply/index.vue')
      },
      {
        path: '/fundlogs/index',
        name: 'fundlogs',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/fundlogs/index.vue')
      },
      {
        path: '/recharge/index',
        name: 'rechargeindex',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/recharge/index.vue')
      },
      {
        path: '/recharge/records',
        name: 'rechargerecords',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/recharge/records.vue')
      },
      {
        path: '/recharge/order',
        name: 'rechargeorder',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/recharge/order.vue')
      },
      {
        path: '/refund/index',
        name: 'refund',
        component: () => import(/* webpackChunkName: "systemsettings" */ '@/views/refund/index.vue')
      }
    ]
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes
})

export default router
