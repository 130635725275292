<template>
    <el-dialog custom-class="passdialog" :title="$t('index.edit_pass')" :visible.sync="ispassVisible" :close-on-click-modal="false">
        <el-form class="flex-column" :model="passform" :rules="rules" ref="passform" label-width="180px" :show-message="false">
            <el-form-item :label="$t('index.oldpass')" label-width="120px" prop="password" required>
                <el-input type="password" class="iw-lg" v-model="passform.password"></el-input>
            </el-form-item>
            <el-form-item :label="$t('index.newpass')" label-width="120px" prop="password" required>
                <el-input type="password" class="iw-lg" v-model="passform.newpassword"></el-input>
            </el-form-item>
            <el-form-item :label="$t('index.passconfirm')" label-width="120px" prop="password" required>
                <el-input type="password" class="iw-lg" v-model="passform.secpassword"></el-input>
            </el-form-item>
            <el-form-item label-width="120px">
                <el-button type="primary" @click="submitForm('passform')" :disabled="isbusy" :loading="isbusy">{{ $t('global.save') }}</el-button>
                <el-button @click="resetForm('passform')">{{$t('global.reset')}}</el-button>
            </el-form-item>
        </el-form>
    </el-dialog>
</template>

<script>
    // @ is an alias to /src

    export default {
        name: 'editpass',
        components: {},
        props: ['passVisible'],
        data() {
            var confirmpass = (rule, value, callback) => {
                if (value === '') {
                    callback(new Error(this.$t('index.enterconfirm')));
                } else if (value !== this.ruleForm.pass) {
                    callback(new Error(this.$t('index.confirmdiff')));
                } else {
                    callback();
                }
            };

            return {
                ispassVisible: this.passVisible,
                passform: {
                    password: '',
                    newpassword: '',
                    secpassword: ''
                },
                rules: {
                    password: [
                        { required: true, trigger: 'blur' },
                        { min: 6, max: 16, trigger: 'blur' }
                    ],
                    newpassword: [
                        { required: true, trigger: 'blur' },
                        { min: 6, max: 16, trigger: 'blur' }
                    ],
                    secpassword: [
                        { validator: confirmpass, trigger: 'blur' }
                    ],
                },
                isbusy: false
            };
        },
        watch: {
            passVisible(val){
                this.ispassVisible = val;
            },
            ispassVisible(val){
                this.$emit('changeVisible', val);
            }
        },
        methods: {
            submitForm(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        this.suresubmit();
                    } else {
                        return false;
                    }
                });
            },
            async suresubmit(){
                this.isbusy = true;
                let res= await this.httppost('/manager/profile/editpass',this.passform);
                if(res.code===0){
                    this.$message({type: 'success', message: res.msg});
                    this.resetForm('passform');
                    this.ispassVisible = false;
                    this.isbusy = false;
                }else{
                    this.$message({type: 'error', message: res.msg});
                    this.isbusy = false;
                }
            },
            resetForm(formName) {
                this.$refs[formName].resetFields();
            }
        }
    }
</script>

<style lang="scss">
    .passdialog {
        width: 450px !important;
    }
    .flex-column{
        display: flex;
        flex-direction: column;
    }

</style>
